import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"

Vue.use(VueRouter)

const routes = [
     {
          path: "/",
          name: "home",
          component: () => import("../views/HomeView.vue"),
     },
     {
          path: "/terminkalender",
          name: "calendar",
          component: () => import("../views/CalendarView.vue"),
     },
     {
          path: "/termin/:link",
          name: "event",
          component: () => import("../views/EventView.vue"),
     },
     {
          path: "/aktuelles",
          name: "news",
          component: () => import("../views/NewsView.vue"),
     },
     {
          path: "/artikel/:link",
          name: "newsarticle",
          component: () => import("../views/ArticleView.vue"),
     },
     {
          path: "/schulanmeldung",
          name: "schoolregistration",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/schulregeln",
          name: "schoolrules",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/impressum",
          name: "imprint",
          component: () => import("../views/ImprintView.vue"),
     },
     {
          path: "/datenschutz",
          name: "privacypolicy",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/barrierefreiheit",
          name: "accessibility",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/leitbild",
          name: "statement",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/ganztageskonzept",
          name: "fulltimeconcept",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/grundschule",
          name: "primaryschool",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/werkrealschule",
          name: "secondaryschool",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/berufsorientierung",
          name: "careerorientation",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/kollegium",
          name: "team",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/schulsozialarbeit",
          name: "socialwork",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/elternbeirat",
          name: "parentscouncil",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/downloads",
          name: "downloads",
          component: () => import("../views/CmsView.vue"),
     },
     {
          path: "/404",
          name: "notFound",
          component: () => import("../views/CmsView.vue"),
     },
     {
          // Catch-all Route für alle anderen Pfade
          path: "*",
          redirect: "/404", // Umleitung auf die 404-Seite
     },
]

const router = new VueRouter({
     mode: "history",
     base: process.env.BASE_URL,
     routes,
     scrollBehavior(to) {
          // Wenn ein Anchor-Link vorhanden ist, zum Element scrollen
          if (to.hash) {
               return {
                    selector: to.hash,
               }
          }
          // Standardmäßig zur oberen Position der Seite scrollen
          return { x: 0, y: 0 }
     },
})
router.beforeEach((to, from, next) => {
     store.commit("updateActiveMenuPoint", null)
     store.commit("setPreviewImage", null)
     store.commit("updateNavOpen", false)
     next()
})

export default router
